import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import useSiteMetadata from '../components/SiteMetadata'

import PageContentWrapper from '../components/PageContentWrapper'
import Img from 'gatsby-image'

export const BlogPostTemplate = ({
  content,
  category,
  author,
  authorLink,
  authorTitle,
  title,
  date
}) => {
  return (
    <PageContentWrapper>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden xl:block xl:absolute xl:inset-y-0 xl:h-full xl:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto">
            <svg className="fixed right-0 -mr-64 mt-20" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg className="fixed left-0 bottom-0 mb-40 -ml-64" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto mb-6">
            <p className="text-base text-center leading-6 text-blue-900 font-semibold tracking-wide uppercase">
              {category}
            </p>
            <h1 className="mt-2 mb-4 text-3xl text-center leading-8 font-medium tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              {title}
            </h1>
            <p className="text-base text-center leading-6 font-semibold tracking-wide uppercase">
             By {authorLink ? <span class="prose"><a href={authorLink}>{author}</a></span> : author}{authorTitle && `, ${authorTitle}`} &middot; {date}
            </p>
          </div>
          <div className="prose prose-lg text-gray-800 mx-auto" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </PageContentWrapper>
  )
}

export const CaseStudyTemplate = ({
  featuredimage,
  content,
  category,
  title,
}) => {
  return (
  <PageContentWrapper>
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
        <div className="mx-auto text-base max-w-prose lg:max-w-none">
          <p className="text-base leading-6 text-blue-900 font-semibold tracking-wide uppercase">
            {category}
          </p>
          <h1 className="mt-2 mb-8 text-3xl leading-8 font-medium tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {title}
          </h1>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
            <svg className="hidden lg:block fixed top-0 right-0 mt-40 -mr-40" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            {
              featuredimage &&
              <div className="hidden lg:block text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="relative pb-7/12 lg:pb-0">
                    <Img
                      className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto"
                      alt="case-study-preview"
                      fluid={featuredimage.childImageSharp.fluid}
                      style={{
                        width: 1184,
                        height: 1376
                      }}
                    />
                  </div>
                </figure>
              </div>
            }
          </div>
          <div>
            <div
              className="prose text-gray-800 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
    </div>
  </PageContentWrapper>
)}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
    const { title, description, url } = useSiteMetadata() 
  const postCategory = typeof post.frontmatter.category === 'string' ? post.frontmatter.category : post.frontmatter.category[0];
  const component = (
    <BlogPostTemplate
      content={post.html}
      category={post.frontmatter.category}
      description={post.frontmatter.description}
      tags={post.frontmatter.tags}
      authorLink={post.frontmatter.authorLink}
      authorTitle={post.frontmatter.authorTitle}
      title={post.frontmatter.title}
      author={post.frontmatter.author}
      date={post.frontmatter.date}
    />
  );

  return (
    <Layout pageTitle={post.frontmatter.title}>
      <Helmet titleTemplate={`${post.frontmatter.title}`}>
        <title>{`${post.frontmatter.title}`}</title>
        <meta
          name="description"
          content={`${post.frontmatter.description}`}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`${post.frontmatter.title}`} />
        <meta name="twitter:description" content={`${post.frontmatter.description}`} />
      </Helmet>
      {component}
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        category
        tags
        author
        authorLink
        authorTitle
      }
    }
  }
`
